import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import ShortcodesList from '@/components/Editor/SuggestionsShortcodesList.vue'
import ApiService from '@/services/ApiService'
import actorTypes from '@/utils/editor/extensions/signature/actorTypes'
import signTypes from "@/utils/editor/extensions/signature/signTypes";
export default {
  char: '{{',
  prefix: '{{',
  suffix: '}}',
  command: ({ editor, range, props }) => {
    // increase range.to by one when the next node is of type "text"
    // and starts with a space character
    const nodeAfter = editor.view.state.selection.$to.nodeAfter
    const overrideSpace = nodeAfter?.text?.startsWith(' ')
    const isSignature = props.id.includes('signature:')
    if (overrideSpace && !isSignature) {
      range.to += 1
    }

    let node = props.id.startsWith('signature:') || props.id.startsWith('initials:')
      ? {
        type: 'signature',
        attrs: {
          actor: props.id.split(':').pop(),
          signatureType: props.id.split(':').shift(),
          id: editor.commands.generateUuid(),
        },
      }
      : {
        type: 'shortcode',
        attrs: {
          key: props.id,
          id: editor.commands.generateUuid(),
          validationEnabled: editor.storage.shortcodesValidator.validationModeIsOn
        },
      }

    editor
      .chain()
      .focus()

      .insertContentAt(range, [
        node,
        {
          type: 'text',
          text: ' ',
        },
      ])
      .run()
  },
  items: async ({ query }) => {
    let res = await ApiService.api.shortcodes.getCompanyShortcodes()
    let shortcodes = res.data.data.map(s => s.placeholder.replace('{{', '').replace('}}', ''))
    shortcodes = shortcodes.concat([
      `${signTypes.SIGN_TYPE_SIGNATURE}:${actorTypes.ACTOR_RECEIVER}`,
      `${signTypes.SIGN_TYPE_SIGNATURE}:${actorTypes.ACTOR_SENDER}`,
      `${signTypes.SIGN_TYPE_INITIALS}:${actorTypes.ACTOR_SENDER}`,
      `${signTypes.SIGN_TYPE_INITIALS}:${actorTypes.ACTOR_RECEIVER}`,
    ])
    return shortcodes.filter(item => item.toLowerCase().startsWith(query.toLowerCase())).slice(0, 5)
  },
  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(ShortcodesList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          // using vue 3:
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate(props) {
        component.updateProps(props)

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit() {
        if (popup && popup[0]){
          popup[0].destroy()
        }
        component?.destroy()
      },
    }
  },
}
