import { Node, mergeAttributes } from '@tiptap/core'
import { VueNodeViewRenderer } from '@tiptap/vue-3'
import SignatureComponent from './TemplateSignature.vue'
import actorTypes from '@/utils/editor/extensions/signature/actorTypes'
import { editorModes } from '@/utils/editor/extensions/utils'

export const Signature = Node.create({
  name: 'signature',
  addStorage(){
    return {
      currentActor: actorTypes.ACTOR_RECEIVER,
      lastClickedId: null,
    }
  },
  onUpdate({ editor, transaction }){
    //prevent signature nodes ids duplication: regenerate id for nodes with duplicated ids
    //apply only in edit mode and for "paste" transaction event
    if ( transaction.meta.paste && editor.storage.CCUtils.mode === editorModes.MODE_EDIT ){
      let signatures = editor.commands.getNodesByType('signature')
      let usedSignaturesIds = []
      const chain = editor.chain()
      const uuidGenerator = editor.commands.generateUuid

      signatures.forEach( signature => {
        if (usedSignaturesIds.includes(signature.id)){
          chain.setNodeSelection(signature.position).updateAttributes('signature', {id: uuidGenerator()})
        } else {
          usedSignaturesIds.push(signature.id)
        }
      })
      chain.run()
    }

  },
  addCommands(){
    return {
      setActor: (actor) => ({editor}) => {
          editor.storage.signature.currentActor = actor
          return true
      },
      setLastClickedSignatureId: (id) => ({editor}) => {
          editor.storage.signature.lastClickedId = id
          return true
      },
    }
  },
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ node }) {
        return `{{signature:${node.attrs.actor ?? node.attrs.id}}}`
      },
      getSignatureUrl(){
        return Promise.resolve()
      },
      getInitialsUrl(){
        return Promise.resolve()
      }
    }
  },

  group: 'inline',
  inline: true,
  atom: true,
  draggable: true,
  selectable: true,
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id,
          }
        },
      },

      actor: {
        default: null,
        parseHTML: element => element.getAttribute('data-actor'),
        renderHTML: attributes => {
          if (!attributes.actor) {
            return {}
          }

          return {
            'data-actor': attributes.actor,
          }
        },
      },
      imageData: {
        default: null,
        parseHTML: element => element.getAttribute('data-image-data'),
        renderHTML: attributes => {
          if (!attributes.imageData) {
            return {}
          }

          return {
            'data-image-data': attributes.imageData,
          }
        },
      },
      signatureType: {
        default: null,
        parseHTML: element => element.getAttribute('data-signature-type'),
        renderHTML: attributes => {
          if (!attributes.signatureType) {
            return {}
          }

          return {
            'data-signature-type': attributes.signatureType,
          }
        },
      },
      availableForSign: { // indicate whether node could be signed by current actor
        default: 0,
        // parseHTML: element => element.getAttribute('data-available-for-sign'),
        // renderHTML: attributes => {
        //   if (!attributes.availableForSign) {
        //     return {}
        //   }
        //   return {
        //     'data-available-for-sign': attributes.availableForSign,
        //   }
        // },
      }
    }
  },

  parseHTML() {
    return [
      {
        tag: `[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({node, HTMLAttributes}) {
    return [
      'img',
      mergeAttributes({
        'data-type': this.name,
        'src': node.attrs.imageData,
        'style': 'max-width: 120px; max-height: 60px;',
      },
        HTMLAttributes ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ]
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },
  addNodeView() {
    return VueNodeViewRenderer(SignatureComponent)
  },
})
