<template>
  <div class="templates-edit">
    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'Templates' }"> Templates </router-link>
      </CBreadcrumbItem>
      <CBreadcrumbItem active>
        <div style="width: 300px;">
          <span class="text-overflow-ellipsis">{{ templateName }}</span>
        </div>
      </CBreadcrumbItem>
    </teleport>

    <UploadFiles
      ref="uploadNewVersionInput"
      class="update-new-version"
      :multiple="false"
      :allowed-formats="['DOCX']"
      :template-id="$route.params.id"
      @update-data="updateData"
    />
    <ConfirmDialog ref="confirmUploadNewVersion" />
    <ConfirmDialog ref="confirmArchiveTemplate" />
    <ConfirmDialog ref="confirmRouteLeave" />
    <ConfirmDialog ref="confirmDownload" />

    <CCard class="min-vh-50 mb-4">
      <CCardBody class="p-0">
        <page-content-loading-spinner v-if="!tiptap" />
        <div class="d-flex" style="max-height: 70vh; height: 100%">
          <div
            class="w-100"
            style="overflow: hidden; display: flex; flex-direction: column"
          >
            <editor-menu
              v-if="tiptap && canEdit"
              :tip="showTip"
              :editor="tiptap"
              :show-shortcodes="showShortcodes"
              @open-shortcodes="openShortcodes"
            />
            <div id="editor-modals-holder" ref="editorModalsHolder"></div>
            <Transition name="fade-in">
              <editor-content-viewer v-if="tiptap" :editor="tiptap" />
            </Transition>
          </div>


          <div v-if="tiptap && canEdit" class="editor-right-side">
            <template-settings-sidebar
              ref="settings"
              class="editor-right-side-settings"
              :template="template"
              :labels="labelsList"
              :content-has-changed="contentHasChanged"
              :disabled-save="disabledSave"
              :update-setting-changes="updateSettingChanges"
              @cancel-changes="setInitialContent"
              @save-changes="saveChanges"
              @isEditForm="setIsEditForm"
            />

            <template-shortcodes
              v-show="showShortcodes"
              :visible="showShortcodes"
              :task-type="template.task_action"
              :editor="tiptap"
              class="editor-right-side-shortcodes"
              @close="closeShortcodes"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script setup>
import {
  onMounted,
  computed,
  ref,
  watch,
  onUnmounted,
  inject,
  nextTick,
  getCurrentInstance,
} from 'vue'
import { downloadFile } from '@/utils/files'
import { useStore } from 'vuex'
import { useRoute, useRouter, onBeforeRouteLeave } from 'vue-router'
const store = useStore()
const route = useRoute()
const router = useRouter()
const template = computed(() => store.getters['template/editableTemplate'])
const canEdit = computed(() => store.state.template.permissions.edit)
const tiptap = ref(null)
const contentHasChanged = ref(false)
import ApiService from '@/services/ApiService'
import { initTemplateEditorWithContent } from '@/utils/editor/editorFactory'
import EditorMenu from '@/components/Editor/TemplateEditorMenu'
import EditorContentViewer from '@/components/Editor/EditorContentViewer'
import PageContentLoadingSpinner from '@/components/PageContentLoadingSpinner'
import TemplateSettingsSidebar from '@/views/Templates/Partials/TemplateSettingsSidebar'
import TemplateShortcodes from '@/views/Templates/Partials/TemplateShortcodes'
import UploadFiles from '@/views/Templates/Components/UploadFiles'
import { permissionError } from '@/services/Notify/Toasts'
import Gate from '@/services/Gate/Gate'
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import { serialize } from 'object-to-formdata'

const mountedComponent = inject('mountedComponent')
const toast = inject('toast')
const downloadToast = inject('downloadToast')
const originTiptapContent = ref(null)
const templateName = ref('')
const showShortcodes = ref(false)

const showTip = ref(true)

setTimeout(() => showTip.value = false, 5000);

const confirmRouteLeave = ref(null)
const isEditForm = ref(false)
onBeforeRouteLeave(async () => {
  if (isEditForm.value) {
    await confirmRouteLeave.value
      ?.confirm({
        text: 'You have unsaved changes. <br /> Are you sure you want to cancel them?',
        confirmText: 'Leave',
        cancelText: 'Cancel',
        reverse: true,
      })
      .then((response) => {
        if (response) {
          return true
        } else {
          return Promise.reject(false)
        }
      })
  } else {
    return true
  }
})

const setIsEditForm = (value) => {
  isEditForm.value = value
}

const updateData = async () => {
  if (tiptap.value) {
    await tiptap.value.destroy()
    tiptap.value = null
  }

  await initialSetUp()
}

const confirmUploadNewVersion = ref(null)
const uploadNewVersionInput = ref(null)
const confirmArchiveTemplate = ref(null)
const confirmDownload = ref(null)
const needToDownload = ref(false)
const settings = ref(null)

const uploadNewVersion = async () => {
  const check = await Gate.can('edit', 'template')
  if (!check) {
    toast('error', permissionError)
    return
  }

  const popupData = {
    text: 'Upload new version',
    cancelText: 'Cancel',
    info: `Are you sure that you want to upload a new version of <b>${templateName.value}</b> template?  Status will be changed to Draft.`,
    confirmText: 'Upload new version',
    onlyConfirmButton: false,
    reverse: true,
  }

  confirmUploadNewVersion.value?.confirm(popupData).then((response) => {
    if (response) {
      nextTick(() => {
        uploadNewVersionInput.value?.$refs.fileInput.click()
      })
    }
  })
}
const archiveTemplate = async () => {
  const data = {
    templates: [{ template_id: route.params.id }],
  }

  await confirmArchiveTemplate.value
    .confirm({
      text: `Are you sure you want to archive ${templateName.value}?`,
      cancelText: 'Cancel',
      confirmText: 'Archive',
      reverse: true,
    })
    .then(async (res) => {
      if (res) {
        const formData = serialize(data, { indices: true })
        const localData = Object.fromEntries(formData)

        await store
          .dispatch('deleteEntity', localData)
          .then((response) => {
            toast('success', response)
            router.push({ name: 'Templates' })
          })
          .catch((error) => {
            const items =
              error.response.data.errors?.projects ||
              error.response.data.errors?.scopes ||
              []
            const joinNames = items?.map((el) => el.name).join(', ')
            const message = `${error.response.data.errors.message} ${joinNames}`

            toast('error', message)
          })
      }
    })
}

const getTemplate = async () => {
  let res = await store.dispatch('template/getTemplate', {
    template_id: route.params.id,
  })

  await store.commit('template/SET_EDITABLE_TEMPLATE', res.data.data)
  if (window.editorInstance && !window.editorInstance.isDestroyed) {
    await window.editorInstance.destroy()
    window.editorInstance = null
  }

  originTiptapContent.value = res.data.data.template_content
  templateName.value = res.data.data.template_name

  tiptap.value = initTemplateEditorWithContent({
    html: res.data.data.template_content,
    template: res.data.data,
    editable: canEdit.value,
  })
  store.commit('template/SET_EDITABLE_TEMPLATE_EDITOR', tiptap)
  window.editor = tiptap.value
}

const labelsList = ref([])

const getTemplatesLabels = async () => {
  await store.dispatch('getLabelsList', {}).then((response) => {
    labelsList.value = response
  })
}

const setInitialContent = async () => {
  tiptap.value.commands.setContent(originTiptapContent.value)
  contentHasChanged.value = false
}

const disabledSave = ref(false)
const updateSettingChanges = ref(false)
const saveChanges = async (data) => {
  const formData = {
    ...data,
    content: tiptap.value.getHTML(),
  }

  disabledSave.value = true
  await ApiService.api.template
    .updateTemplate({
      formData,
      template_id: route.params.id,
    })
    .then(async (response) => {
      toast('success', 'The changes have been saved successfully')
      contentHasChanged.value = false
      updateSettingChanges.value = true
      templateName.value = response.data.data.template_name
      await updateData()
      if (needToDownload.value) {
        needToDownload.value = false
        await download()
      }
    })
    .catch((error) => {
      const errorMessage = error.response.data.errors?.name

      toast('error', errorMessage ? errorMessage[0] : error.response.data.message || 'Error! Try again later')
    })
    .finally(() => {
      disabledSave.value = false
      updateSettingChanges.value = false
    })
}

const openShortcodes = () => {
  showShortcodes.value = !showShortcodes.value
}
const closeShortcodes = () => {
  showShortcodes.value = false
}

const handleDownload = async () => {
  if (isEditForm.value) {
    await confirmDownload.value
      ?.confirm({
        text: 'You have unsaved changes in the Template. Do you want to save them before downloading?',
        confirmText: 'Save and download',
        cancelText: 'Cancel',
        reverse: true,
      })
      .then(async (response) => {
        if (response) {
          needToDownload.value = true
          await settings.value.saveChanges()
        }
      })
  } else {
    await download()
  }
}

const download = async () => {
  downloadToast.open(route.params.id, 'Download')
  await ApiService.api.common
    .downloadSingleDocument({
      type: 'template',
      template_id: route.params.id,
      content_type: 'content',
    })
    .then((response) => {
      downloadFile(response, template.value.name + '.doc')
      downloadToast.update(route.params.id)
    })
}

const editorModalsHolder = ref(null)

const EventBus =
  getCurrentInstance().appContext.config.globalProperties.$EventBus

const initialSetUp = async () => {
  await getTemplate()
  await getTemplatesLabels()

  EventBus.emit('updateTopActions', [
    {
      label: 'Upload new version',
      event: uploadNewVersion,
    },
    {
      label: 'Archive',
      event: archiveTemplate,
    },
    {
      label: 'Download',
      event: handleDownload,
    },
  ])

  //watch signatures removing. set "is_published" to false if removed all signatures or there are signatures only for one side
  //this form data sends with template content ( see editorFactory.js )
  store.state.template.editableTemplateEditor?.on('update', ({ editor }) => {
    contentHasChanged.value = true

    if (
      !store.getters['template/currentEditableTemplateHasSignaturesForBothSide']
    ) {
      store.commit('template/SET_EDITABLE_TEMPLATE_ATTRIBUTE', {
        key: 'is_published',
        value: false,
      })
    }
  })
}

onMounted(async () => {
  await initialSetUp()
})
onUnmounted(async () => {
  if (tiptap.value) {
    await tiptap.value.destroy()
  }
})

watch(
  () => template.value.template_content,
  (to, from) => {
    if (from) {
      tiptap.value?.chain().setContent(to).run()
      setTimeout(() => {
        tiptap.value?.commands.convertSignatures()
      }, 250)
    }
  },
)
</script>

<style scoped lang="scss">
.update-new-version {
  display: none;
}

.editor-right-side {
  position: relative;
  border-left: 1px solid;
  max-width: 350px;
  width: 100%;
  flex-shrink: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  &-settings {
    z-index: 1;
  }

  &-shortcodes {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 2;
  }
}

.templates-edit {
  &__back {
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__actions-item {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

#editor-modals-holder {
  position: absolute;
  right: 65px;
}
.do-back-btn {
  :deep(svg) {
    transform: scaleX(-1);
    margin-bottom: -2px;
  }
}
</style>
