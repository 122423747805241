import { Node } from '@tiptap/core'

export const Page = Node.create({
  name: 'page',
  content: 'block*',
  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ]
  },
  renderHTML() {
    return [
      'div',
      { 'data-type': this.name },
      0,
    ]
  },
})
