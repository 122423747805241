import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'signaturesConverter',
  priority: 1000,
  addOptions() {
    return {
      regexRule: '{{((signature|initials):(buyer|seller))}}',
      regexFlags: 'ig',
    }
  },
  addCommands() {
    const regexRule = new RegExp(
      this.options.regexRule,
      this.options.regexFlags,
    )

    return {
      insertSignature:
        (attrs = {}) =>
        ({ commands }) => {
          commands.insertContent([
            {
              type: 'signature',
              attrs: {
                ...{
                  id: commands.generateUuid(),
                },
                ...attrs,
              },
            },
            {
              type: 'text',
              text: ' ',
            },
          ])
          return true
        },
      convertSignatures:
        () =>
        ({ chain, commands }) => {
          let nodes = commands.getMatchedTextNodes(regexRule)

          let chainQueue = chain()
          let nextNodeToConvert = nodes.pop()

          while (nextNodeToConvert) {
            nextNodeToConvert.from -= 1
            chainQueue.insertContentAt(nextNodeToConvert, [
              {
                type: 'signature',
                attrs: {
                  actor: nextNodeToConvert.content.split(':').pop(),
                  signatureType: nextNodeToConvert.content.split(':').shift(),
                  id: commands.generateUuid(),
                },
              },
            ])
            nextNodeToConvert = nodes.pop()
          }

          chainQueue.run()
        },
      resetSignatures: () => ({chain, commands}) => {
        let queue = chain()
        let signatures = commands.getNodesByType('signature').reverse()
        signatures.forEach(({position}) => {
          queue.setNodeSelection(position)
            .updateAttributes('signature', {imageData: null})
        })
        queue.run()
        return true
      }
    }
  },
  onCreate({ editor }) {
    editor.chain().convertSignatures().run()
  },
})
