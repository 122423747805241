import { Node, mergeAttributes } from '@tiptap/core'

export const Shortcode = Node.create({
  name: 'shortcode',
  addOptions() {
    return {
      HTMLAttributes: {},
      renderLabel({ options, node }) {
        return `${options.prefix}${node.attrs.key ?? node.attrs.id}${options.suffix}`
      },
      prefix: '{{',
      suffix: '}}',
    }
  },

  group: 'inline',

  inline: true,

  selectable: true,

  draggable: true,

  atom: false,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: element => element.getAttribute('data-id'),
        renderHTML: attributes => {
          if (!attributes.id) {
            return {}
          }

          return {
            'data-id': attributes.id,
          }
        },
      },

      key: {
        default: null,
        parseHTML: element => element.getAttribute('data-key'),
        renderHTML: attributes => {
          if (!attributes.key) {
            return {}
          }

          return {
            'data-key': attributes.key,
          }
        },
      },

      valid: {
        default: 1,
        parseHTML: element => element.getAttribute('data-valid'),
        renderHTML: attributes => {
          if (!attributes.valid) {
            return {}
          }

          return {
            'data-valid': attributes.valid,
          }
        }
      },

      validationEnabled: {
        default: 0,
        parseHTML: element => element.getAttribute('data-validation-enabled'),
        renderHTML: attributes => {
          if (attributes.validationEnabled === undefined) {
            return {}
          }

          return {
            'data-validation-enabled': attributes.validationEnabled,
          }
        }
      },

    }
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ]
  },

  renderHTML({ node, HTMLAttributes}) {
    let showValidation = parseInt(HTMLAttributes['data-validation-enabled'])
    let additionalAttributes = showValidation
      ? {style: parseInt(HTMLAttributes['data-valid']) ? 'color: green' : 'color: red'}
      : {}
    return [
      'span',
      mergeAttributes({ 'data-type': this.name, ...additionalAttributes}, this.options.HTMLAttributes, HTMLAttributes),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ]
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    })
  },
})
