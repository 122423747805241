import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'shortcodesValidator',
  priority: 1000,
  addStorage() {
    return {
      validationModeIsOn: 0,
    }
  },
  addCommands() {
    return {
      getAvailableShortcodeNodes:
        () =>
        ({ commands }) => {
          return commands.getNodesByType('shortcode')
        },
      setValidationMode:
        (status) =>
        ({ commands, chain, editor }) => {
          editor.storage.shortcodesValidator.validationModeIsOn = status
          let shortcodes = commands.getAvailableShortcodeNodes()
          let chainQueue = chain()
          shortcodes.forEach(({ position }) => {
            chainQueue
              .setNodeSelection(position)
              .updateAttributes('shortcode', { validationEnabled: status })
          })
          chainQueue.run()
          return true
        },
      validateShortcodes:
        (availableShortcodes) => {
        let response =  ({ commands, chain }) => {
          let chainQueue = chain()
          let shortcodes = commands.getAvailableShortcodeNodes()

          shortcodes.forEach(({ position, key }) => {
            let valid = availableShortcodes.includes(`{{${key?.toLowerCase()}}}`) ? 1 : 0
            chainQueue
              .setNodeSelection(position)
              .updateAttributes('shortcode', { valid })
          })

          chainQueue.run()

          return true
        }
        return response
        },
    }
  },
})
