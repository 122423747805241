<template>
  <node-view-wrapper
    as="span"
    :data-signature-node-id="node.attrs.id"
    class="template-signature"
    :class="wrapperClasses"
    @click.self="handleClick"
  >
    <span style="width: 50px" key="tools">
      <svg v-if="editor.storage.CCUtils.mode === 'edit'"
           class="drag-handle"
           contenteditable="false"
           draggable="true"
           data-drag-handle xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 16"><path fill-opacity="0.4" d="M4 14c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zM2 6C.9 6 0 6.9 0 8s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6C.9 0 0 .9 0 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" /></svg>
      <span  v-if="signing" :class="['btn-spinner c-loading-button-spinner spinner-border spinner-border-sm']"></span>
      <img v-else src="@/assets/document-sign.svg" alt="document sign" />
    </span>
    <span key="content" >
      <img
        v-if="node.attrs.imageData"
        :src="node.attrs.imageData"
        class="signature"
        alt="signature"
        @click.self.stop="handleClick"
      />
      <span v-else class="label" @click.self.stop="handleClick">{{ labelText }}</span>
    </span>
    <span class="actions" key="actions">
      <button
        v-show="node.attrs.imageData && ( node.attrs.actor === editor?.storage?.signature?.currentActor && isMode('sign') || isMode('edit'))"
        class="btn btn-sm btn-close"
        @click="unsetSignature"
      ></button>


      <confirmation-modal ref="confirmSetSignatureModal" />
    </span>
  </node-view-wrapper>

</template>

<script>
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-3'
import actorTypes from '@/utils/editor/extensions/signature/actorTypes'
import signTypes from '@/utils/editor/extensions/signature/signTypes'
import ConfirmationModal from '@/components/Modals/ConfirmationModal'
import {editorEvents, editorModes} from '@/utils/editor/extensions/utils'
import {parseError} from "@/utils/api";

export default {
  components: {
    NodeViewWrapper,
    ConfirmationModal,
  },

  props: nodeViewProps,
  data: () => ({
    clickedInRow: 0,
    signing: false,
  }),
  inject: ['toast'],
  computed: {
    isSimpleMode(){
      return this.isMode(editorModes.MODE_READ_ONLY)
      || (
        this.isMode(editorModes.MODE_SIGN)
        && this.node.attrs.actor === actorTypes.ACTOR_RECEIVER
        && this.editor?.storage?.signature?.currentActor === actorTypes.ACTOR_SENDER
      )
    },
    wrapperClasses() {
      let readyForSignInSignMode = this.node.attrs.availableForSign && this.isMode(editorModes.MODE_SIGN) && this.editor.storage.signature.lastClickedId === this.node.attrs.id
      let selectedInEditMode = this.selected && this.isMode(editorModes.MODE_EDIT)
      return {
        'is-selected':  readyForSignInSignMode || selectedInEditMode,
        'clickable': this.selected && this.isMode('sign') && this.node.attrs.availableForSign,
        'has-text-muted': this.isMode(editorModes.MODE_SIGN) && !this.node.attrs.availableForSign,
        'hide-ui': this.isSimpleMode,
      }
    },
    labelText() {
      if ( [editorModes.MODE_READ_ONLY, editorModes.MODE_EDIT].includes( this.editor.storage.CCUtils.mode) ||
        this.node.attrs.availableForSign === 0
      ) {
        let type =
          signTypes.SIGN_TYPE_SIGNATURE === this.node.attrs.signatureType
            ? 'Signature'
            : 'Initials'
            // ? this.$t('Signature')
            // : this.$t('Initials')
        return actorTypes.ACTOR_RECEIVER === this.actorType
          ? `Seller ${type}`
          : `Buyer ${type}`
      }
      if (this.editor.storage.CCUtils.mode === editorModes.MODE_SIGN) {
        let type = signTypes.SIGN_TYPE_SIGNATURE === this.node.attrs.signatureType
        ? 'signature'
        : 'initials'
            // ? this.$t('signature')
            // : this.$t('initials')

        return `${this.$t('Click to add')} ${type}`
      }

      return 'Signature'
    },
    actorType() {
      return this.node.attrs.actor
    },
  },
  watch: {
    'editor.storage.CCUtils.mode': function (to, from) {
      if (to !== from && to === editorModes.MODE_SIGN) {
        this.enableNodesForSigning()
      }
    },
  },
  mounted() {
    this.enableNodesForSigning()
  },
  methods: {
    handleClick() {
      if (
        this.editor.storage.CCUtils.mode !== editorModes.MODE_SIGN ||
        !this.node.attrs.availableForSign
      ) return

      if (this.extension.storage.lastClickedId === this.node.attrs.id){
        if (this.node.attrs.imageData) return

        this.confirmSettingSignature()
      } else {
        this.editor.commands.setLastClickedSignatureId(this.node.attrs.id)
        // this.clickedInRow = 0
      }

      // if (this.clickedInRow > 0){
      //   this.confirmSettingSignature()
      // }

    },
    async confirmSettingSignature() {
      this.signing = true
      this.extension.options
        .setSignatureCallback({
          signature_id: this.node.attrs.id,
          signature_type: this.node.attrs.signatureType,
          contract_id: this.editor.storage.CCUtils.contract?.id,
        })
        .then((res) => {
          if (res.status === 200 && res.data.imageBase64) {
            this.updateAttributes({
              imageData: res.data.imageBase64,
            })

            this.editor.commands.getEmitter().emit(editorEvents.EVENT_SIGNATURE_SET, this.node)
          } else {
            this.editor.commands
              .getEmitter()
              .emit(editorEvents.EVENT_SIGNATURE_EMPTY, this.node)
          }
        }).catch((error) => {
          let err = parseError(error)
          this.toast('error', err.message)
      }).finally(() => {
        this.signing = false
      })
    },
    unsetSignature(){
      this.updateAttributes({ imageData: null })
      this.editor.commands.getEmitter().emit(editorEvents.EVENT_SIGNATURE_UNSET, this.node)
    },
    isMode(mode) {
      return this.editor.storage.CCUtils.mode === mode
    },
    enableNodesForSigning() {
      let actor = this.editor.storage.signature.currentActor
      let mode = this.editor.storage.CCUtils.mode

      if (mode === editorModes.MODE_SIGN) {
        if (this.node.attrs.actor === actor) {
          this.updateAttributes({ availableForSign: 1 })
        } else {
          this.updateAttributes({ availableForSign: 0 })
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.template-signature {
  & > span {
    display: block;
  }
  display: inline-flex;
  justify-content: space-between;
  min-width: 240px;
  max-width: 260px;
  &.is-selected {
    box-shadow: 0 0 4px #00487b;
  }
  &.has-text-muted {
    color: grey;
  }
  box-sizing: border-box;
  border-radius: 5px;
  padding: 9px;
  border: 1px dashed #c8c3f2;

  img {
    height: 20px;
    &.signature {
      height: 30px !important;
      margin: -10px;
    }
  }
  .actions {
    display: flex;
    .btn-close {
      margin-top: 2px;
      margin-right: 4px;
    }

  }
  .drag-handle {
    width: 1rem;
    height: 1.3rem;
    margin-right: .5rem;
    cursor: grab;
  }
}
</style>
