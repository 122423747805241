import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'DocumentFontFamily',
  addOptions() {
    return {
      fonts: ['Arial', 'Times New Roman', 'Verdana', 'Century'],
      defaultFont: 'Arial',
    }
  },
  addCommands() {
    return {
      setDocumentFontFamily:
        (fontFamily) =>
        ({ commands }) => {
          const chosenFontFamily = fontFamily || this.options.defaultFont
          return commands.updateAttributes('contentWrapper', {
            fontFamily: chosenFontFamily,
          })
        },
    }
  },
  addGlobalAttributes() {
    return [
      {
        types: ['contentWrapper'],
        attributes: {
          fontFamily: {
            default: null,
            parseHTML: (element) => {
              return element.style.fontFamily || this.options.defaultFont
            },
            renderHTML: (attributes) => {
              if (!attributes.fontFamily) {
                return {}
              }
              return {
                style: `font-family: ${attributes.fontFamily}`,
              }
            },
          },
        },
      },
    ]
  },
})
