<template>
  <div class="template-shortcodes">
    <div class="template-shortcodes__header">
      <CButton
        class="template-shortcodes__close"
        size="sm"
        color="link"
        @click="close"
      >
        <CIcon name="cil-arrow-left"></CIcon>
        Back
      </CButton>

      <span class="template-shortcodes__heading">Merge fields</span>
    </div>

    <div class="template-shortcodes__body">
      <div class="template-shortcodes__item">
        <!--        <CButton-->
        <!--          class="template-shortcodes__button"-->
        <!--          color="primary"-->
        <!--          @click="openValidationPopover"-->
        <!--        >-->
        <!--          Validate Merge Fields-->
        <!--        </CButton>-->

        <div
          v-if="showValidationPopover"
          class="template-shortcodes__validation"
        >
          <TemplateValidationMergeFields
            class="template-shortcodes__validation-popover"
            :total="total"
            :valid="valid"
            :invalid="invalid"
            :nodes="nodes"
            :loading="loadingValidation"
            @selectShortcode="handleShortcodeSelect"
            @close="closeValidationPopover"
          />
        </div>
      </div>

      <CNSelect
        v-model="filter.type"
        class="template-shortcodes__item"
        label="Type"
        :disabled="loading"
        :options="shortcodesTypes"
      />

      <div class="fields-search">
        <CIcon class="fields-search__icon" icon="cil-search"></CIcon>
        <input
          :disabled="loading"
          v-model="filter.search"
          class="fields-search__input"
          type="text"
          placeholder="All Merge Fields" />
      </div>
      <ul class="template-shortcodes__item template-shortcodes__list">
        <li
          v-if="loading"
          class="template-shortcodes__list-item template-shortcodes__list-item--spinner"
        >
          <CSpinner color="secondary" />
        </li>

        <template v-else-if="shortcodesList.length">
          <li
            v-for="shortcode in shortcodesList"
            :key="shortcode.id"
            class="template-shortcodes__list-item"
          >
            <button
              class="template-shortcodes__list-button"
              type="button"
              @click="handleInsertNode(shortcode)"
            >
              {{ shortcode.name }}
            </button>
          </li>
        </template>

        <li
          v-else
          class="template-shortcodes__list-item template-shortcodes__list-item--spinner"
        >
          {{ $t('No fields found') }}
        </li>
      </ul>

      <div class="template-shortcodes__buttons">
        <CButton color="primary" variant="outline" @click="close">Cancel</CButton>
        <CButton color="primary" @click="openValidationPopover">Validate</CButton>
      </div>

    </div>
  </div>
</template>

<script>
import TemplateValidationMergeFields from '@/views/Templates/Partials/TemplateValidationMergeFields'
export default {
  name: 'TemplateShortcodes',
  components: { TemplateValidationMergeFields },
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    visible: {
      type: Boolean,
      default: false,
    },
    taskType: {
      type: String,
      default: ''
    }
  },
  emits: ['close'],
  data() {
    return {
      availableShortcodes: [],
      validationAvailableShortcodes: [],
      shortcodesTypes: [],

      filter: {
        type: 'All',
        search: '',
      },

      total: 0,
      valid: 0,
      invalid: 0,
      nodes: [],

      loading: true,

      showValidationPopover: false,
      loadingValidation: false,
    }
  },
  computed: {
    shortcodesList() {
      let result = this.availableShortcodes
      if (this.filter.type && this.filter.type !== 'All') {
        result = result.filter((s) => s.type === this.filter.type)
      }

      if (this.filter.search) {
        result = result.filter((s) =>
          s.name.toLowerCase().includes(this.filter.search.toLowerCase()),
        )
      }

      return result
    },
    fieldToMerge() {
      if (this.taskType === 'Acknowledgement of Receipt Required') return []

      return [
        {
          id: 'signatureSender',
          name: 'Buyer Signature',
          placeholder: 'signature:buyer',
          actor: 'buyer',
          type: 'Signature',
        },
        {
          id: 'signatureReceiver',
          name: 'Seller Signature',
          placeholder: 'signature:seller',
          actor: 'seller',
          type: 'Signature',
        },
        {
          id: 'initialsSender',
          name: 'Buyer Initials',
          placeholder: 'initials:buyer',
          actor: 'buyer',
          type: 'Initials',
        },
        {
          id: 'initialsReceiver',
          name: 'Seller Initials',
          placeholder: 'initials:seller',
          actor: 'seller',
          type: 'Initials',
        },
      ]
    }
  },
  watch: {
    visible(visible) {
      if (!visible) {
        this.closeValidationPopover()
        this.filter = {
          type: 'All',
          search: '',
        }
      }
    },
  },
  mounted() {
    this.fetchAvailableShortcodes()
  },
  methods: {
    async fetchAvailableShortcodes() {
      this.loading = true
      await this.$http.shortcodes
        .getCompanyShortcodes()
        .then((response) => {
          this.availableShortcodes = this.mergeSignatureShortcodes(
            response.data.data,
          )
          this.validationAvailableShortcodes = response.data.data.map((s) =>
            s.placeholder.toLowerCase(),
          )
          this.shortcodesTypes = [
            'All',
            ...new Set(this.availableShortcodes.map((s) => s.type)),
          ]
        })
        .catch((error) => {})
        .finally(() => (this.loading = false))
    },
    mergeSignatureShortcodes(shortcodes = []) {
      return [
        ...(shortcodes || []),
        ...this.fieldToMerge,
      ]
    },
    handleInsertNode(node) {
      if (String(node?.id).startsWith('signature')) {
        this.editor
          .chain()
          .focus()
          .insertSignature({
            actor: node.actor,
            signatureType: 'signature',
          })
          .run()
      } else if (String(node?.id).startsWith('initials')) {
        this.editor
          .chain()
          .focus()
          .insertSignature({
            actor: node.actor,
            signatureType: 'initials',
          })
          .run()
      } else {
        this.editor
          .chain()
          .focus()
          .insertShortcode({
            key: node.placeholder.replace('{{', '').replace('}}', ''),
          })
          .run()
      }
    },
    close() {
      this.$emit('close')
    },
    async openValidationPopover() {
      this.showValidationPopover = true
      this.loadingValidation = true

      await this.editor?.commands.convertShortcodes()
      await this.editor?.commands.setValidationMode(1)
      await this.editor
        ?.chain()
        .validateShortcodes(this.validationAvailableShortcodes)
        .run()
      await this.calculateReport()

      this.loadingValidation = false
    },
    closeValidationPopover() {
      this.showValidationPopover = false
      this.editor?.chain().setValidationMode(0).run()
    },
    async calculateReport() {
      this.total = await this.editor?.commands.getAvailableShortcodeNodes()
        .length
      this.valid = await this.editor?.commands
        .getAvailableShortcodeNodes()
        ?.filter((n) => parseInt(n.valid)).length
      this.invalid = await this.editor?.commands
        .getAvailableShortcodeNodes()
        .filter((n) => !parseInt(n.valid)).length
      this.nodes = await this.editor?.commands.getAvailableShortcodeNodes()
    },
    async handleShortcodeSelect(node) {
      let nodes = await this.editor?.commands.getAvailableShortcodeNodes()
      let position = nodes.find((el) => el.id === node.id)?.position

      this.editor
        ?.chain()
        .focus()
        .setNodeSelection(position)
        .scrollIntoView()
        .run()
    },
  },
}
</script>

<style lang="scss" scoped>
.template-shortcodes {
  padding: 0 20px 20px 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;

  &__buttons {
    margin-top: auto;
    border-top: 1px solid #C3CDD5;
    display: flex;
    background-color: #fff;
    z-index: 10;
    gap: 15px;
    padding-top: 25px;
  }

  &__header {
    position: sticky;
    top: -20px;
    z-index: 2;
    background: #fff;
    display: flex;
    flex-direction: column;
  }

  &__heading {
    font-size: 20px;
    font-weight: 600;
    color: #1C262F;
    border-top: 1px solid #C3CDD5;
  }

  &__close {
    height: 38px;
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 0;
    font-weight: 600;
    color: #1C262F;
    font-size: 16px;
    text-decoration: none;
    width: 60px;
  }

  &__body {
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__validation {
    position: relative;
  }

  &__validation-popover {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    z-index: 2;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 0 0 8px 8px;
    border-left: 1px solid #9FAFBC;
    border-right: 1px solid #9FAFBC;
    border-bottom: 1px solid #9FAFBC;
    overflow: hidden;
    flex-grow: 1;
    overflow-y: auto;
    height: 300px;
    max-height: 300px;
  }

  &__list-button {
    width: 100%;
    padding: 8px 16px;
    background-color: transparent;
    border: none;
    text-align: left;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }
  }

  &__list-item {
    &--spinner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &__button {
    width: 100%;
  }
}
.fields-search {
  border: 1px solid #9fafbc;
  border-radius: 4px 4px 0 0;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  gap: 10px;

  &__search {
    height: 15px;
    color: #BAC6CF;
  }

  &__input {
    flex: auto;
    border: none;
    outline: none;
    font-size: 14px;

    &::placeholder {
      color: #BAC6CF;
    }
  }
}
</style>
