import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'contentSaver',
  addStorage() {
    return {
      saving: false,
    }
  },
  addOptions() {
    return {
      callback: (htmlContent) => {
        return new Promise((resolve) => {
          resolve({ status: 200, content: htmlContent })
        })
      },
    }
  },
  addCommands() {
    return {
      isSaving: () => () => {
        return this.storage.saving
      },
      pushContentToDB:
        () =>
        ({ editor }) => {
          if (typeof this.options.callback === 'function') {
            if (!editor.storage.shortcodesValidator.validationModeIsOn) {
              this.storage.saving = true
              return this.options
                .callback(editor.getHTML())
                .catch((error) => {})
                .finally(() => {
                  this.storage.saving = false
                })
            } else {
              return Promise.reject()
            }
          }
        },
    }
  },
})
