<template>
  <div class="template-validation">
    <template v-if="!loading">
      <div class="template-validation__header">
        <h3 class="template-validation__heading">Validate merge fields</h3>

        <CButton
          color="link template-validation__close"
          size="sm"
          @click="close"
        >
          <CIcon name="cilX" />
        </CButton>
      </div>

      <div class="template-validation__content">
        <ul class="template-validation__list">
          <li
            v-for="type in types"
            :key="type.slug"
            class="template-validation__list-item"
          >
            <button
              type="button"
              class="template-validation__button"
              :class="{
                'template-validation__button--active': activeType === type.slug,
              }"
              @click="setType(type.slug)"
            >
              {{ type.label }}:
              <span class="template-validation__number">{{ type.number }}</span>
            </button>
          </li>
        </ul>

        <ul
          v-if="filteredNodes.length"
          class="template-validation__list template-validation__shortcodes"
        >
          <li
            v-for="node in filteredNodes"
            :key="node.id"
            class="template-validation__list-item"
          >
            <button
              class="template-validation__button"
              :class="{
                'text-success': parseInt(node.valid),
                'text-danger': !parseInt(node.valid),
              }"
              @click.prevent="selectShortcode(node)"
            >
              {{ renderLabel(node) }}
            </button>
          </li>
        </ul>
      </div>
    </template>

    <div v-else class="template-validation__spinner">
      <CSpinner color="secondary" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TemplateValidationMergeFields',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    total: {
      type: Number,
      default: 0,
    },
    valid: {
      type: Number,
      default: 0,
    },
    invalid: {
      type: Number,
      default: 0,
    },
    nodes: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close', 'selectShortcode'],
  data() {
    return {
      shortcodes: [],
      activeType: 'all',
    }
  },
  computed: {
    types() {
      return [
        {
          label: 'Syntax errors',
          slug: 'invalid',
          number: this.invalid,
        },
        {
          label: 'Successfully validated',
          slug: 'valid',
          number: this.valid,
        },
        {
          label: 'All fields validated',
          slug: 'all',
          number: this.total,
        },
      ]
    },
    filteredNodes() {
      return this.nodes
        ?.sort((a, b) => a.position - b.position)
        .filter(({ valid }) => {
          switch (this.activeType) {
            case 'valid':
              return parseInt(valid)
            case 'invalid':
              return !parseInt(valid)
            default:
              return true
          }
        })
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    setType(type) {
      this.activeType = type
    },
    renderLabel(node) {
      return `{{${node.key}}} ${
        parseInt(node.valid) ? 'Passed' : "Field Doesn't Exist"
      }`
    },
    selectShortcode(node) {
      this.$emit('selectShortcode', node)
    },
  },
}
</script>

<style lang="scss" scoped>
.template-validation {
  width: 100%;
  min-height: 180px;
  background-color: #fff;
  border: 1px solid #9fafbc;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 2px 0
      rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.14),
    0 3px 1px -2px rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.12),
    0 1px 5px 0 rgba(var(--cui-elevation-base-color, 60, 75, 100), 0.2);

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px 8px;
  }

  &__heading {
    font-size: 18px;
    font-weight: 500;
    margin: 0;
  }

  &__close {
    color: inherit;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__shortcodes {
    max-height: 250px;
    overflow-y: auto;
    border-top: 1px solid #9fafbc;
  }

  &__number {
    font-weight: 500;
  }

  &__button {
    background-color: transparent;
    border: none;
    width: 100%;
    text-align: left;
    padding: 5px 16px;

    &:hover {
      background-color: rgba(238, 238, 239, 0.7);
    }

    &--active {
      font-weight: 500;
      background-color: rgba(238, 238, 239, 0.4);
    }
  }
}
</style>
