<template>
  <div class="template-editor-viewer" :class="`${viewName}-view`">
    <editor-content :editor="editor" :style="scaleTransformationStyle" />
  </div>
</template>

<script>
import { EditorContent } from '@tiptap/vue-3'
export default {
  name: 'EditorContentViewer',
  components: { EditorContent },
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
    viewName: {
      type: String,
      default: 'template',
    },
  },
  computed: {
    scaleTransformationStyle() {
      return `transform: scale(${
        this.$store.state.template.editorViewScalePercents / 100
      })`
    },
  },
}
</script>

<style scoped lang="scss">
.template-editor-viewer {
  &.template-view {
    //height: 600px;
  }
  &.review-approve-view {
    max-height: 1200px;
  }
  &.sign-view {
    height: 600px;
  }
  background-color: #f7f7f7;
  overflow-y: scroll;
  position: relative;
}
:deep(.ProseMirror) {
  min-height: 29.7cm;
  background-color: white;
  width: 21cm;
  padding: 10mm 15mm;
  margin: 0.5rem auto 1cm auto;
  background-clip: padding-box;
  border: solid transparent;
  border-width: 1px 1px 1px 6px;
  box-shadow: inset 1px 1px 0 rgb(0 0 0 /10%), inset -1px -1px 0 rgb(0 0 0 /7%);

  &.resize-cursor {
    cursor: col-resize;
  }

  [data-type='shortcode'] {
    display: inline-block;
    cursor: pointer;
    border: 1px dashed transparent;
    &.ProseMirror-selectednode {
      cursor: grabbing;
      border: 1px dashed grey;
      border-radius: 5px;
    }
  }
  [data-type='pageBreaker'] {
    background-color: #f7f7f7;
    margin: 15mm -15mm 10mm;
    height: 3rem;
  }
  &:focus-visible,
  &:focus {
    outline: none;
  }
  display: block;
  //margin: 0.5rem auto 0.5cm auto;
  //width: 21cm;
  //min-height: 29.7cm;
  //padding: 10mm 15mm;
  //background-clip: padding-box;
  //border: solid transparent;
  //border-width: 1px 1px 1px 6px;
  //box-shadow: inset 1px 1px 0 rgb(0 0 0 /10%), inset -1px -1px 0 rgb(0 0 0 /7%);
  .discussion-decoration {
    background-color: rgba(255, 255, 0, 0.7);
    &.is-active {
      background-color: yellowgreen;
    }
    &.is-approve {
      background-color: #adff2f;
    }
    &.is-reject {
      background-color: #fc9696d1;
    }
  }
  .tableWrapper {
    margin: 0 0 1rem 0;
    overflow-x: auto;
  }
  table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    overflow: hidden;
    td,
    th {
      min-width: 1em;
      border: 2px solid #ced4da;
      padding: 3px 5px;
      vertical-align: top;
      box-sizing: border-box;
      position: relative;
      p {
        margin-bottom: 0;
      }
    }
    th {
      //font-weight: 700;
      //text-align: left;
      //background-color: #f1f3f5;
    }

    .selectedCell:after {
      z-index: 2;
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(200, 200, 255, 0.4);
      pointer-events: none;
    }

    .column-resize-handle {
      position: absolute;
      right: -2px;
      top: 0;
      bottom: -2px;
      width: 4px;
      background-color: #adf;
      pointer-events: none;
    }
  }
}
</style>
