const dayNames = [
  'monday',
  'tuesday',
  'wednesday',
  'thuesday',//TODO fix typo
  'friday',
  'saturday',
  'sunday',
]
const getDaysConfigFromDaysNumbersArray = function(dayNumbers ) {
  let daysConfig = {}
  dayNames.forEach((name, index) => {
    daysConfig[name] = dayNumbers.includes(index + 1)
  })
  return daysConfig
}
const getDaysNumbersFromConfigObject = function(daysConfigObject){
  let dayNumbers = []
  Object.values(daysConfigObject).map((item, index) => {
    if (item) dayNumbers.push(index + 1)
  })
  return dayNumbers
}

const templateTaskSettingsDataSchemaNormalizer = ( resource) => {
  return {
    due_date_days: resource.due_date_days,
    checkDays: resource.due_date_days,
    reminders: resource.reminders_enabled ? 'custom' : null,
    reminders_every: resource.reminders_every,
    reminders_period: resource.reminders_period,
    reminders_days: getDaysConfigFromDaysNumbersArray(resource.reminders_days),
    recurrence: resource.recurrence_enabled ? 'custom' : null,
    recurrence_every: resource.recurrence_every,
    recurrence_period: resource.recurrence_period,
    recurrence_until_type: resource.recurrence_type,
    recurrence_until_repeats: resource.recurrence_until_repeats,
    recurrence_until_date: resource.recurrence_until_date,
    recurrence_days: getDaysConfigFromDaysNumbersArray(resource.recurrence_days),
  }
}
const templateTaskSettingsBeforeModelValueUpdateEmit = function(data, modelValue){
  return {
    ...modelValue,
    ...{
      due_date_days: data.due_date_days,
      reminders_enabled: data.reminders === 'custom',
      reminders_every: data.reminders_every,
      reminders_period: data.reminders_period,
      reminders_days: getDaysNumbersFromConfigObject(data.reminders_days),
      // reminders_until: data.1,
      recurrence_enabled: data.recurrence === 'custom',
      recurrence_every: data.recurrence_every,
      recurrence_period: data.recurrence_period,
      recurrence_days: getDaysNumbersFromConfigObject(data.recurrence_days),
      recurrence_type: data.recurrence_until_type,
      recurrence_until_date: data.recurrence_until_date,
      recurrence_until_repeats: data.recurrence_until_repeats,
    }
  }
}

export const templateTaskActionsSettingsConfiguration = () => {
  return {
    dataSchemaNormaliser: templateTaskSettingsDataSchemaNormalizer,
    dataSchemaDeNormalizer: templateTaskSettingsBeforeModelValueUpdateEmit,
    withRangeAndAssignDates: false,
    withDaysAfterAssigned: true,
    registerWatchers: function (vm){
      vm.$watch('value.checkDays', function (to) {
        if (!to) {
          this.value.due_date_days = 0
        }
      })
      vm.$watch('value.reminders', function (to) {
        this.value.reminders_enabled = !!to
      })
      vm.$watch('value.recurrence', function (to) {
        this.value.recurrence_enabled = !!to
      })
    }
  }
}
