<template>
  <div class="editor-toolbox py-2 px-1">
    <div v-if="editor" class="d-flex">
      <div class="items-group">
        <button
          class="btn btn-sm"
          :class="{ 'is-active btn-primary': editor.isActive('bold') }"
          @click="editor.chain().focus().toggleBold().run()"
        >
          <CIcon size="lg" name="cil-bold"></CIcon>
        </button>
        <button
          class="btn btn-sm"
          :class="{ 'is-active btn-primary': editor.isActive('italic') }"
          @click="editor.chain().focus().toggleItalic().run()"
        >
          <CIcon size="lg" name="cil-italic"></CIcon>
        </button>

        <button
          class="btn btn-sm"
          :class="{ 'is-active btn-primary': editor.isActive('underline') }"
          @click="editor.chain().focus().toggleUnderline().run()"
        >
          <CIcon size="lg" name="cil-underline"></CIcon>
        </button>
      </div>
      <div class="items-group" style="padding-right: 0.5rem">
        <editor-text-style :editor="editor" />
      </div>

      <div class="items-group" style="padding-right: 0.5rem">
        <editor-font-families :editor="editor" />
      </div>

      <div class="items-group">
        <button
          class="btn btn-sm"
          :class="{ 'is-active btn-primary': editor.isActive('bulletList') }"
          @click="editor.chain().focus().toggleBulletList().run()"
        >
          <CIcon size="lg" name="cil-list"></CIcon>
        </button>
        <button
          class="btn btn-sm"
          :class="{ 'is-active btn-primary': editor.isActive('orderedList') }"
          @click="editor.chain().focus().toggleOrderedList().run()"
        >
          <CIcon size="lg" name="cil-list-numbered"></CIcon>
        </button>
      </div>

      <div class="items-group">
        <button
          class="btn btn-sm"
          :class="{
            'is-active btn-primary': editor.isActive({ textAlign: 'left' }),
          }"
          @click="editor.chain().focus().setTextAlign('left').run()"
        >
          <CIcon size="lg" name="cil-align-left"></CIcon>
        </button>
        <button
          class="btn btn-sm"
          :class="{
            'is-active btn-primary': editor.isActive({ textAlign: 'center' }),
          }"
          @click="editor.chain().focus().setTextAlign('center').run()"
        >
          <CIcon size="lg" name="cil-align-center"></CIcon>
        </button>
        <button
          class="btn btn-sm"
          :class="{
            'is-active btn-primary': editor.isActive({ textAlign: 'right' }),
          }"
          @click="editor.chain().focus().setTextAlign('right').run()"
        >
          <CIcon size="lg" name="cil-align-right"></CIcon>
        </button>
        <button
          class="btn btn-sm"
          :class="{
            'is-active btn-primary': editor.isActive({ textAlign: 'justify' }),
          }"
          @click="editor.chain().focus().setTextAlign('justify').run()"
        >
          <CIcon size="lg" name="cil-justify-center"></CIcon>
        </button>
      </div>
      <div class="items-group">
        <button class="btn btn-sm" @click="editor.chain().focus().undo().run()">
          <CIcon size="lg" name="cil-action-undo"></CIcon>
        </button>
        <button class="btn btn-sm" @click="editor.chain().focus().redo().run()">
          <CIcon size="lg" name="cil-action-redo"></CIcon>
        </button>
      </div>
      <div class="items-group">
        <CDropdown variant="btn-group">
          <CButton
            class="btn btn-sm mr-0"
            @click="
              editor.chain().focus().insertTable({ rows: 3, cols: 3 }).run()
            "
          >
            <CIcon size="lg" :icon="cilColumns"></CIcon>
          </CButton>
          <CDropdownToggle class="btn btn-sm" split>Table</CDropdownToggle>
          <CDropdownMenu style="max-height: 350px; overflow-y: auto">
            <template v-for="action in tableActions" :key="action.name">
              <CDropdownDivider v-if="action.isDiver" />

              <CDropdownItem v-else href="#" @click="action.event(editor)">
                {{ action.name }}
              </CDropdownItem>
            </template>
          </CDropdownMenu>
        </CDropdown>
      </div>
      <div class="items-group">
        <template v-if="false">
          <shortcodes-validation :editor="editor" />
          <shortcodes-list :editor="editor" />
        </template>

      <div class="hint-wrapper">
        <button class="btn btn-sm" :class="{'is-active btn-primary': showShortcodes}" @click="$emit('openShortcodes')">
          &#10100;&#10100;...&#10101;&#10101;
        </button>

        <div v-if="tip" ref="tipIndicator" class="hint-wrapper__body">
          You just have uploaded a Word document. You can now use this feature to add merge fields if necessary.
        </div>
      </div>

<!--        <button-->
<!--          class="btn btn-sm"-->
<!--          :class="{-->
<!--            'is-active btn-primary': showShortcodes,-->
<!--          }"-->
<!--          @click="$emit('openShortcodes')"-->
<!--        >-->
<!--          &#10100;&#10100;...&#10101;&#10101;-->
<!--        </button>-->
      </div>

      <button
        class="btn btn-sm btn-light d-none"
        @click="showDebugTools = !showDebugTools"
      >
        tools
      </button>

    </div>
  </div>
</template>


<script setup>
import {defineProps, ref, defineEmits, onMounted} from 'vue'
import ShortcodesValidation from '@/components/Editor/ShortcodesValidation.vue'
import ShortcodesList from '@/components/Editor/ShortcodesList.vue'
import EditorTextStyle from '@/components/Editor/EditorTextStyle.vue'
import { cilColumns } from '@coreui/icons'
import EditorFontFamilies from '@/components/Editor/EditorFontFamilies'
import Button from "@/components/SmartTable/Button.vue";
import directive from "click-outside-vue3";
import { useRouter } from "vue-router";
const router = useRouter()

const vClickOutside = directive.directive

const tableActions = [
  {
    name: 'Add column before',
    event: (editor) => {
      editor.chain().focus().addColumnBefore().run()
    },
  },
  {
    name: 'Add column after',
    event: (editor) => {
      editor.chain().focus().addColumnAfter().run()
    },
  },
  {
    name: 'Delete column',
    event: (editor) => {
      editor.chain().focus().deleteColumn().run()
    },
  },
  {
    isDiver: true,
  },
  {
    name: 'Add row before',
    event: (editor) => {
      editor.chain().focus().addRowBefore().run()
    },
  },
  {
    name: 'Add row after',
    event: (editor) => {
      editor.chain().focus().addRowAfter().run()
    },
  },
  {
    name: 'Delete row',
    event: (editor) => {
      editor.chain().focus().deleteRow().run()
    },
  },
  {
    isDiver: true,
  },
  {
    name: 'Delete table',
    event: (editor) => {
      editor.chain().focus().deleteTable().run()
    },
  },
  {
    isDiver: true,
  },
  {
    name: 'Merge cells',
    event: (editor) => {
      editor.chain().focus().mergeCells().run()
    },
  },
  {
    name: 'Split cell',
    event: (editor) => {
      editor.chain().focus().splitCell().run()
    },
  },
]
defineEmits(['openShortcodes'])

const insertPB = () => {
  const chain = props.editor.chain()
  const positions = [100, 200, 300]

  positions.forEach((position) => {
    chain.insertContentAt(position, { type: 'pageBreaker' })
  })
  chain.run()
}
const removePB = () => {
  const breakers = props.editor.commands.getNodesByType('pageBreaker')

  const chain = props.editor.chain()

  breakers.reverse().forEach(({ position }) => {
    chain.setNodeSelection(position).deleteSelection()
  })
  chain.run()
  return true
}

const props = defineProps({
  editor: Object,
  showShortcodes: Boolean,
  tip: Boolean
})
const showDebugTools = ref(false)
const setLink = () => {
  const previousUrl = props.editor.getAttributes('link').href
  const url = window.prompt('URL', previousUrl)

  // cancelled
  if (url === null) {
    return
  }

  // empty
  if (url === '') {
    props.editor.chain().focus().extendMarkRange('link').unsetLink().run()

    return
  }

  // update link
  props.editor
    .chain()
    .focus()
    .extendMarkRange('link')
    .setLink({ href: url })
    .run()
}

</script>

<style scoped lang="scss">
.editor-toolbox {
  .items-group:first-child {
    padding-left: 10px;
  }
  .items-group:not(:first-child) {
    border-left: 1px solid #b1b7c1;
    padding-left: 0.5rem;
  }
  border-bottom: 1px solid lightgrey;
  button {
    margin-right: 0.5rem;
    height: 31px;
    padding-left: 4px;
    padding-right: 4px;
  }
}
.hint-wrapper {
  position: relative;

  &__body {
    position: absolute;
    right: 7px;
    padding: 12px;
    background: #202E39;
    color: white;
    font-size: 14px;
    font-weight: 400;
    width: 240px;
    z-index: 1;
    top: 37px;
    border-radius: 2px;

    &::after {
      content: '';
      position: absolute;
      top: -2px;
      right: 10px;
      background: #202E39;
      width: 5px;
      height: 5px;
      transform: rotate(43deg);
    }
  }
}
</style>
