<template>
    <div ref="wrapper" class="template-settings-sidebar">
        <div class="template-settings-sidebar__body">
            <div>
                <input
                    v-model="formData.name"
                    class="template-settings-sidebar__name w-100 form-text"
                    :class="{
                        'text-error': !!validationErrors['task_name'],
                        'template-settings-sidebar__name_error': !!validationErrors['task_name'] || nameError,
                    }"
                    type="text"
                    maxlength="245"
                    placeholder="Task Name"
                    @blur="validateField('task_name')"
                    @input="onInput" />

                <small v-if="!!validationErrors['task_name']" class="text-error">{{
                    validationErrors['task_name']
                }}</small>
                <small v-if="nameError" class="text-error">
                    Task name can not contain the following symbols: {{ '<>:"/\\|?*' }}
                </small>
            </div>

            <CNSelect
                v-model="formData.task_action"
                :aria-disabled="true"
                :disabled="true"
                class="template-settings-sidebar__item"
                label="Task action"
                required
                :options="taskActionOptions" />

            <CNSelect
                v-model="formData.is_published"
                label="Status"
                required
                :error="validationErrors['task_action'] || errors.task_action"
                :invalid="!!validationErrors['task_action'] || !!errors.task_action"
                :options="taskStatuses" />

            <DueDateSelector
                v-if="recurrenceSettings"
                :settings="recurrenceSettings"
                :permissions="['recurrence']"
                file="Editor"
                @saved="formData = Object.assign(formData, $event)" />

            <CNTextarea v-model="formData.description" label="Description" :max-length="1000" />

            <AddTag :tags="tags" @saved="tags = $event" />

            <ul class="users-edit">
                <li>{{ modifiedBy }}</li>
                <li>{{ createdBy }}</li>
            </ul>
        </div>

        <div class="template-settings-sidebar__footer">
            <CButton
                color="primary"
                variant="outline"
                class="template-settings-sidebar__button"
                :disabled="!isEditForm || disabledSave"
                @click="cancelChanges">
                Cancel
            </CButton>

            <CButton
                color="primary"
                class="template-settings-sidebar__button"
                :disabled="disabledSave || nameError"
                @click="onSave">
                Save
            </CButton>
        </div>

        <ConfirmDialog ref="cancelChanges" />

        <div class="overflow-modal">
            <CModal :visible="draftModal" @close="draftModal = false">
                <div class="draft-modal">
                    <CIcon class="draft-modal__icon" name="cil-file"></CIcon>
                    <div class="draft-modal__body">
                        <h3 class="draft-modal__title">Publish your template</h3>
                        <p class="draft-modal__text">
                            Are you sure you want to keep this template as a draft? You need to change the status to
                            “Published” if you are ready to make the template available in the system.
                        </p>
                    </div>
                    <div class="draft-modal__buttons">
                        <CButton color="primary" variant="outline" @click="draftModal = false">Cancel</CButton>
                        <CButton color="primary" @click="saveChanges">Save draft</CButton>
                    </div>
                </div>
            </CModal>
        </div>
    </div>
</template>

<script>
import CNTextarea from '@/components/ui/CNTextarea/CNTextarea';
import { templateTaskActionsSettingsConfiguration } from '@/views/Templates/Partials/taskActionsSettingsComposable';
import ConfirmDialog from '@/components/Modals/ConfirmDialog';
import { isEqual } from 'lodash/lang';
import validator from '@/utils/validator';
import rules from '@/utils/validator/rules';
import DueDateSelector from '@/components/CreateTask/DueDateSelector.vue';
import AddTag from '@/components/CreateTask/AddTag.vue';
import moment from 'moment/moment';
import getRecurrenceData from '@/utils/recurrence';

export default {
    name: 'TemplateSettingsSidebar',
    components: { AddTag, DueDateSelector, ConfirmDialog, CNTextarea },
    props: {
        template: {
            type: Object,
            default: () => ({}),
        },
        contentHasChanged: {
            type: Boolean,
            default: false,
        },
        updateSettingChanges: {
            type: Boolean,
            default: false,
        },
        disabledSave: {
            type: Boolean,
            default: false,
        },
        labels: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['cancelChanges', 'saveChanges', 'isEditForm'],
    data: (vm) => ({
        tags: [],
        draftModal: false,

        templateTaskActionsSettingsConfiguration,
        formData: {
            template_id: vm.template.template_id,

            is_published: vm.template.is_published ? 'published' : 'draft',
            task_name: vm.template?.task_settings?.task_name || '',
            name: vm.template.template_name,
            task_action: vm.template?.task_settings?.task_action,
            description: vm.template?.template_description || '',
            labels: vm.template?.labels?.map((el) => el.id) || [],

            due_date_days: vm.template?.task_settings?.due_date_days || 0,

            recurrence_day_of_month: vm.template?.task_settings?.recurrence_day_of_month,
            recurrence_days_of_week: vm.template?.task_settings?.recurrence_days_of_week,
            recurrence_enabled: vm.template?.task_settings?.recurrence_enabled,
            recurrence_every: vm.template?.task_settings?.recurrence_every,
            recurrence_period: vm.template?.task_settings?.recurrence_period,
            recurrence_type: vm.template?.task_settings?.recurrence_type,
            recurrence_until_date: vm.template?.task_settings?.recurrence_until_date,
            recurrence_until_repeats: vm.template?.task_settings?.recurrence_until_repeats,
        },
        created_at:
            vm.template?.created_at && vm.template?.created_by_user_name
                ? `${vm.template?.created_by_user_name} ${vm.template?.created_at}`
                : '',
        latest_version:
            vm.template?.modified_date && vm.template?.modified_by_user_name
                ? `${vm.template?.modified_by_user_name} ${vm.template?.modified_date}`
                : '',

        taskActionOptions: [
            {
                value: 'Request',
                name: 'Request Document',
            },
            {
                value: 'Signature Required',
                name: 'Request Signature',
            },
            {
                value: 'Acknowledgement of Receipt Required',
                name: 'Request acknowledgement of receipt',
            },
        ],

        taskStatuses: [
            {
                value: 'draft',
                name: 'Draft',
            },
            {
                value: 'published',
                name: 'Published',
            },
        ],

        validator: {},
        validationErrors: {},
        errors: {},
        origin: {},
        nameError: null,
    }),
    computed: {
        isEditForm() {
            return !isEqual(this.formData, this.origin) || this.contentHasChanged || this.tagsChanged;
        },
        labelsList() {
            return this.labels.map((el) => ({ id: el.value, name: el.label }));
        },
        modifiedBy() {
            if (!this.template.modified_date || !this.template.modified_by_user_name) return;
            const date = moment.utc(this.template.modified_date).local();

            return `Modified by ${this.template.modified_by_user_name} ${date.format('MM/DD/YYYY')} at ${date.format(
                'HH:mm A',
            )}`;
        },
        createdBy() {
            if (!this.template.created_by_user_name || !this.template.created_at) return;
            const date = moment.utc(this.template.created_at).local();

            return `Created by ${this.template.created_by_user_name} ${date.format('MM/DD/YYYY')} at ${date.format(
                'HH:mm A',
            )}`;
        },
        tagsChanged() {
            return this.template.labels.toString() !== this.tags.toString();
        },
        recurrenceSettings() {
            return getRecurrenceData(this.formData);
        },
        requiredSignatures() {
            return this.$store.getters['template/currentEditableTemplateHasSignaturesForBothSide'];
        },
    },
    watch: {
        isEditForm(value) {
            this.$emit('isEditForm', value);
        },
        updateSettingChanges(update) {
            if (!update) return;

            this.origin = this.$deepClone(this.formData);
            this.errors = {};
        },
        errors(errors) {
            if (!Object.keys(errors).length) return;

            this.scrollToErrors();
        },
        'formData.is_published'() {
            this.errors = {
                ...this.errors,
                task_action: '',
            };
        },
        requiredSignatures(val) {
            if (val) {
                this.errors = {
                    ...this.errors,
                    task_action: '',
                };
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.origin = this.$deepClone(this.formData);
            this.setRules();
        });
        this.tags = this.template.labels;
    },
    methods: {
        onInput(e) {
            const data = e.target.value;
            const regex = /[<>:"\/\\|?*]/;
            const isInvalid = regex.test(data);

            this.nameError = isInvalid;
        },
        onSave() {
            if (!this.validateAll()) {
                this.scrollToErrors();
                return;
            }

            if (this.formData.is_published === 'draft') {
                this.draftModal = true;
                return;
            }

            this.saveChanges();
        },
        validateField(key) {
            this.validationErrors[key] = this.validator.validate(key, this.formData[key]);
        },
        validateAll() {
            this.setRules();

            const validationResult = this.validator.validateAll(this.formData);
            this.validationErrors = this.$deepClone(validationResult.validationErrors);
            return !validationResult.hasErrors;
        },
        setRules() {
            this.validator = validator({
                is_published: [rules.required],
                task_name: [rules.required],
                name: [rules.required],
                task_action: [rules.required],
            });
        },
        scrollToErrors() {
            this.$nextTick(() => {
                let els = this.$refs.wrapper.querySelectorAll('.form-text.text-error');

                if (els.length) {
                    const top = els[0].getBoundingClientRect().top;
                    const y = top + window.pageYOffset + -250;
                    this.$refs.wrapper?.scrollTo({ top: y, behavior: 'smooth' });
                }
            });
        },
        cancelChanges() {
            if (this.isEditForm) {
                this.$refs.cancelChanges
                    .confirm({
                        text: this.$t('messages.unsaved'),
                        cancelText: 'Cancel',
                        confirmText: 'OK',
                        reverse: true,
                    })
                    .then((res) => {
                        if (res) {
                            this.formData = this.$deepClone(this.origin);
                            this.validateAll();
                            this.$emit('cancelChanges');
                            this.tags = this.template.labels;
                        }
                    });
            } else {
                this.formData = this.$deepClone(this.origin);
            }
        },
        saveChanges() {
            const isSignatureRequired = this.formData.task_action === 'Signature Required';
            const isPublished = this.formData.is_published === 'published';
            const hasSignatureForBothSide =
                this.$store.getters['template/currentEditableTemplateHasSignaturesForBothSide'];

            if (isPublished && isSignatureRequired && !hasSignatureForBothSide) {
                this.errors = {
                    ...this.errors,
                    task_action:
                        'Please add Buyer and Seller signature merge fields or save the template in Draft status',
                };
                return;
            }

            if (!this.validateAll()) {
                this.scrollToErrors();
                return;
            }

            const data = {
                name: this.formData.name,
                description: this.formData.description,
                is_published: this.formData.is_published === 'published',
                task_settings: {
                    task_name: this.formData.name,
                    task_action: this.formData.task_action,
                    due_date_days: this.formData.due_date_days,
                    recurrence_enabled: this.formData.recurrence_enabled,
                    recurrence_every: this.formData.recurrence_every,
                    recurrence_period: this.formData.recurrence_period,
                    recurrence_days_of_week: this.formData.recurrence_days_of_week,
                    recurrence_type: this.formData.recurrence_type,
                    recurrence_until_date: this.formData.recurrence_until_date,
                    recurrence_until_repeats: this.formData.recurrence_until_repeats,
                    recurrence_day_of_month: this.formData.recurrence_day_of_month,
                },
                labels: this.tags.map((tag) => tag.id),
            };

            this.$emit('saveChanges', data);
            this.draftModal = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.draft-modal {
    padding: 40px;
    width: 688px;

    &__icon {
        height: 80px !important;
        width: 80px !important;
        color: #bac6cf;
        margin: 0 auto;
        display: block;
    }

    &__title {
        font-size: 24px;
        font-weight: 600;
    }

    &__text {
        color: #677a89;
        font-size: 18px;
    }

    &__body {
        text-align: center;
        margin: 40px 0;
    }

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 15px;

        & button {
            min-width: 200px;
        }
    }
}

.users-edit {
    padding: 10px 0 10px 18px;
    border-top: 1px solid silver;

    & li {
        font-size: 12px;
        color: #677a89;
    }
}

.template-settings-sidebar {
    position: relative;
    overflow-y: auto;
    padding: 0 20px;

    &__name {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #c3cdd5;
        color: #1c262f;
        font-size: 20px;
        font-weight: 600;
        outline: none;

        &_error {
            border-bottom: 1px solid red;
        }
    }

    &__header {
        position: sticky;
        top: -20px;
        z-index: 10;
        margin: -20px;
        padding: 20px;
        background: #fff;
    }

    &__heading {
        font-size: 24px;
        font-weight: 600;
    }

    &__body {
        margin-top: 40px;
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 25px;
    }

    &__item {
        &:not(:last-child) {
            margin-bottom: 15px;
        }
    }

    &__info {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__info-item {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }

    &__label {
        flex: 0 0 50%;
    }

    &__value {
        flex: 1 1 50%;
    }

    &__footer {
        position: sticky;
        bottom: -10px;
        margin: -20px;
        padding: 20px;
        background-color: #fff;
        z-index: 10;
    }

    &__button {
        min-width: 100px;

        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}
</style>
