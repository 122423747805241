<template>
  <select
    v-model="selected"
    class="editor-font-families form-select"
    @change="onSelectChange"
  >
    <option value="noFont" disabled>Font family</option>
    <option v-for="family in staticData.families" :key="family" :value="family">
      {{ family }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'EditorFontFamilies',
  props: {
    editor: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      selected: 'Arial',
      staticData: Object.seal({
        families: ['Arial', 'Century', 'Times New Roman', 'Verdana'],
      }),
    }
  },
  watch: {
    selected(font) {
      this.$EventBus.emit('setTemplateFontFamily', font)
    },
  },
  mounted() {
    this.activeFontFamily()
  },
  methods: {
    onSelectChange() {
      this.editor.chain().setDocumentFontFamily(this.selected).focus().run()
      this.activeFontFamily()
    },
    activeFontFamily() {
      if (this.editor.isActive({ fontFamily: 'Verdana' })) {
        this.selected = 'Verdana'
      } else if (this.editor.isActive({ fontFamily: 'Century' })) {
        this.selected = 'Century'
      } else if (
        this.editor.isActive({ fontFamily: `"Times New Roman"` }) ||
        this.editor.isActive({ fontFamily: 'Times New Roman' })
      ) {
        this.selected = 'Times New Roman'
      } else {
        this.selected = 'Arial'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.editor-font-families {
  margin-right: 0.5rem;
  height: 31px;
  padding: 0.1rem 2.25rem 0.1rem 0.75rem;
  max-width: 120px;
}
</style>
