<template>
  <div>
    <input
      ref="fileInput"
      type="file"
      :multiple="multiple"
      :accept="acceptedFormats"
      @change="onFileChange"
    />
  </div>
</template>

<script>
import Converter, {
  availableTargetExtensions,
} from '@/utils/templatesConverter/Converter'
import { serialize } from 'object-to-formdata'
import { DEFAULT_REQUEST_DOCUMENT_SETTINGS } from '@/store/template.module'
import { mapActions } from 'vuex'
const converter = new Converter()

export default {
  name: 'UploadFiles',
  inject: ['toast'],
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    templateId: {
      type: Number,
      default: undefined,
    },
    packageId: {
      type: Number,
      default: undefined,
    },
    priority: {
      type: String,
      default: '',
    },
    allowedFormats: {
      type: Array,
      default: () => [
        'DOCX',
        'XLSX',
        'XLS',
        'PDF',
        'JPEG',
        'JPG',
        'GIF',
        'EMP',
        'SVG',
        'TXT',
      ],
    },
  },
  emits: ['updateData'],
  data() {
    return {
      files: [],
      errorMessage: null,
      maxFileSize: 200 * 1024 * 1024, // 200 MB
    }
  },
  computed: {
    canUpload() {
      return this.files.length > 0 && !this.errorMessage
    },
    acceptedFormats() {
      return this.allowedFormats?.map((el) => `.${el}`) || []
    },
  },
  methods: {
    ...mapActions({
      addShortcut: 'addShortcut',
    }),

    onFileChange() {
      this.errorMessage = null
      this.files = Array.from(this.$refs.fileInput.files)
      if (!this.multiple && this.files.length > 1) {
        this.errorMessage = 'Please select a single file'
        this.files = []
        return
      }
      this.files.forEach((file) => {
        if (
          !this.allowedFormats.includes(
            file.name.split('.').pop()?.toUpperCase(),
          )
        ) {
          this.errorMessage = `Invalid file format: ${file.name}`
          return
        }
        if (file.size > this.maxFileSize) {
          this.errorMessage = `File size too large: ${file.name}`
          return
        }
      })

      if (this.errorMessage) {
        this.toast('error', this.errorMessage)
      }

      if (!this.errorMessage && this.files.length) {
        this.uploadFiles()
        this.$refs.fileInput.value = null
      }
    },
    uploadFiles() {
      this.files.forEach((file) => {
        //File convertable
        const extension = file.name.split('.').pop()
        if (Object.values(availableTargetExtensions).includes(extension)) {
          converter
            .convertFile(file)
            .then((res) => {
              let name = file.name.split('.')
              name.pop()
              let data = {
                type: 'Contracts',
                name: name.join('.'),
                content: res.html,
                is_published: false,
              }
              if (this.packageId) {
                data.folder_id = this.packageId
                data.priority = this.priority
              }

              if (this.multiple) {
                this.$http.library
                  .createTemplate(data)
                  .then((response) => {
                    this.toast('success', response.data.message)
                    this.updateData()
                  })
                  .catch((error) => {
                    const message =
                      error.response.data?.errors?.name[0] ||
                      error.response.data?.message ||
                      'Error'
                    this.toast('error', message)
                  })
              } else {
                this.$http.library
                  .uploadNewVersion(this.templateId, data.content)
                  .then((response) => {
                    this.toast('success', response.data.message)
                    this.updateData()
                  })
                  .catch((error) => {
                    this.toast('error', error.response.data.message)
                  })
              }
            })
            .catch(() => {
              this.toast('error', 'The file is damaged or in the wrong format')
            })
        } else {
          //File not convertable, create manual signature task settings
          let fileName = file.name.split('.')
          fileName.pop()
          fileName = fileName.join('.')
          const formState = serialize(
            {
              ...DEFAULT_REQUEST_DOCUMENT_SETTINGS,
              ...{
                file,
                manual_signature: 1,
                name: fileName ?? 'Manual Signature Task',
                task_name: fileName ?? 'Manual Signature Task',
              },
            },
            { booleansAsIntegers: true },
          )

          this.$http.tasks
            .createTaskSetting(formState)
            .then((response) => {
              this.toast('success', response.data.message)

              if (this.packageId) {
                const data = {
                  template_id: [response.data.template_id],
                  add_to_folder_id: this.packageId,
                  type: this.priority,
                }
                this.addShortcut(data).then(() => {
                  this.updateData()
                })
              } else {
                this.updateData()
              }
            })
            .catch((error) => {
              this.toast('error', error.response.data.message)
            })
        }
      })
    },
    updateData() {
      this.$emit('updateData')
    },
  },
}
</script>
