import { Extension } from '@tiptap/core'
import mitt from 'mitt'
import {v4 as uuidv4 } from 'uuid'
import { Plugin } from 'prosemirror-state'

export const editorEvents = {
  EVENT_SIGNATURE_SET: 'signature.set',
  EVENT_SIGNATURE_UNSET: 'signature.unset',
  EVENT_SIGNATURE_EMPTY: 'signature.empty',
  EVENT_DISCUSSION_CREATED: 'discussion:created',
  EVENT_DISCUSSION_DELETED: 'discussion:deleted',
  EVENT_DISCUSSION_UPDATED: 'discussion:updated',
  EVENT_DISCUSSION_APPROVED: 'discussion:approved',
  EVENT_DISCUSSION_ROLLED_BACK: 'discussion:rolledBack',
}

export const editorModes = {
  MODE_EDIT: 'edit',
  MODE_SIGN: 'sign',
  MODE_READ_ONLY: 'readonly',
  MODE_PROPOSITION: 'proposition',
}

const makeId = () => {
  // var result = ''
  // var characters =
  //   'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  // var charactersLength = characters.length
  // for (var i = 0; i < length; i++) {
  //   result += characters.charAt(Math.floor(Math.random() * charactersLength))
  // }
  return uuidv4()
}

export default Extension.create({
  name: 'CCUtils',
  priority: 900,
  addStorage(){
    return {
      mode: 'edit',
      emitter: null,
    }
  },
  addCommands() {
    return {
      generateUuid: () => () => {
        return makeId()
      },
      refreshDecorationsView: () => ({state, editor}) => {
        if (state.selection.empty){
          editor.commands.setTextSelection(0)
        } else {
          const {from, to} = state.selection
          editor.commands.setTextSelection({from, to})
        }
        return true
      },
      getEmitter: () => ({editor}) => {
        return editor.storage.CCUtils.emitter
      },
      setMode: (mode) => ({editor}) => {
        editor.storage.CCUtils.mode = mode
        return true
      },
      emitCustomEvent: ({name, ...payload}) => ({commands}) => {
        commands.getEmitter().emit(name, payload)
        return true
      },
      getMatchedTextNodes:
        (regex) =>
        ({ editor }) => {
          let textNodesWithPosition = []
          const results = []

          let index = 0

          editor.state.doc.descendants((node, pos) => {
            if (node.isText) {
              if (textNodesWithPosition[index]) {
                textNodesWithPosition[index] = {
                  text: textNodesWithPosition[index].text + node.text,
                  pos: textNodesWithPosition[index].pos,
                }
              } else {
                textNodesWithPosition[index] = {
                  text: `${node.text}`,
                  pos,
                }
              }
            } else {
              index += 1
            }
          })

          textNodesWithPosition = textNodesWithPosition.filter(Boolean)

          for (let i = 0; i < textNodesWithPosition.length; i += 1) {
            const { text, pos } = textNodesWithPosition[i]

            const matches = [...text.matchAll(regex)]

            for (let j = 0; j < matches.length; j += 1) {
              const m = matches[j]

              if (m[0] === '') break

              if (m.index !== undefined) {
                results.push({
                  from: pos + m.index,
                  to: pos + m.index + m[0].length,
                  content: m[1],
                })
              }
            }
          }

          return results
        },
      /**
       * @param {'shortcode'|'signature'} type
       */
      getNodesByType:
        (type) =>
        ({ editor }) => {
          let nodes = []
          editor.state.doc.descendants(async (node, position) => {
            if (node.type.name === type) {
              nodes.push({ ...node.attrs, ...{ position } })
            }
          })
          return nodes
        },
    }
  },
  onBeforeCreate({ editor }) {
    editor.storage.CCUtils.emitter = mitt()
  },
})
