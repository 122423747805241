import { Extension } from '@tiptap/core'

export default Extension.create({
  name: 'shortcodeConverter',
  priority: 1000,
  addOptions() {
    return {
      regexRule: '{{([a-zA-Z_:\\.]+)}}',
      regexFlags: 'ig',
    }
  },
  addCommands() {
    const regexRule = new RegExp(
      this.options.regexRule,
      this.options.regexFlags,
    )

    return {

      insertShortcode: (attrs = {}) => ({commands, editor}) => {

        commands.insertContent([
          {
            type: 'shortcode',
            attrs: {
              ...{
                id: commands.generateUuid(),
                validationEnabled: editor.storage.shortcodesValidator.validationModeIsOn,
              },
              ...attrs
            }
          },
          {
            type: 'text',
            text: ' ',
          }
        ])
        return true;
      },
      convertShortcodes:
        () =>
        ({ chain, commands }) => {
          let nodes = commands.getMatchedTextNodes(regexRule)
          let chainQueue = chain()
          let nextNodeToConvert = nodes.pop()
          while (nextNodeToConvert) {
            chainQueue.insertContentAt(nextNodeToConvert, [
              {
                type: 'shortcode',
                attrs: {
                  key: nextNodeToConvert.content,
                  id: commands.generateUuid(),
                  validationEnabled: 0,
                },
              },
            ])
            nextNodeToConvert = nodes.pop()
          }

          chainQueue.run()
        },
      convertShortcodesLegacy:
        () =>
        ({ editor, commands }) => {
          let templateHtml = editor.getHTML()
          if (templateHtml.match(regexRule)) {
            let nextShortcode = true
            while (nextShortcode) {
              templateHtml = templateHtml.replace(
                regexRule,
                `<span data-id="${commands.generateUuid()}" data-key="$1" data-label="$1" data-valid="0" data-type="shortcode" ></span>`,
              )
              nextShortcode = templateHtml.match(regexRule)
            }

            commands.setContent(templateHtml)
          }
          return true
        },
    }
  },
})
