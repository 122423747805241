<template>
  <select v-model="contentType" class="form-select" @input="applyStyleToSelection">
    <option value="noStyle" disabled>Style</option>
    <option v-for="style in styles" :key="style.type" :value="style.type" >{{ style.label }}</option>
  </select>
</template>

<script setup>
import { defineProps, onMounted, ref} from 'vue'

const props = defineProps({
  editor: {
    type: Object,
    default: () => ({}),
  }
})

const styles = [
  { type: 'h1', label: 'Heading 1'},
  { type: 'h2', label: 'Heading 2'},
  { type: 'h3', label: 'Heading 3'},
  { type: 'paragraph', label: 'Paragraph'},
]

const contentType = ref('noStyle')

const setActiveStyle = () => {
  let type = styles.find(s => {
    switch (s.type){
      case 'h1':
        return props.editor.isActive('heading', { level: 1 })
      case 'h2':
        return props.editor.isActive('heading', { level: 2 })
      case 'h3':
        return props.editor.isActive('heading', { level: 3 })
      case 'paragraph':
        return props.editor.isActive('paragraph');
      default:
        return {type: 'noStyle'}
    }
  })

  contentType.value = type?.type || 'noStyle'
}
const applyStyleToSelection = (event) => {
  switch (event.target.value){
    case 'h1':
      props.editor?.chain().focus().toggleHeading({ level: 1 }).run()
      break
    case 'h2':
      props.editor?.chain().focus().toggleHeading({ level: 2 }).run()
      break
    case 'h3':
      props.editor?.chain().focus().toggleHeading({ level: 3 }).run()
      break
    case 'paragraph':
      props.editor?.chain().focus().setParagraph().run()
      break
  }
}

onMounted(() => {
  props.editor?.on('selectionUpdate', () => {
    setActiveStyle()
  })
})

</script>

<style scoped lang="scss">
select {
  margin-right: 0.5rem;
  height: 31px;
  padding: 0.1rem 2.25rem 0.1rem 0.75rem;

}
</style>
