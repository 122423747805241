import { Extension, mergeAttributes } from '@tiptap/core'
import { Decoration, DecorationSet } from 'prosemirror-view'
import { Plugin, TextSelection } from 'prosemirror-state'
import store from '@/store'

export default Extension.create({
  name: 'discussionDecoration',
  addProseMirrorPlugins(){

    /*dom element to hold discussion range, id*/
    const createDiscussionDataHolderWidget = (discussion) => {
      let dataHolder = document.createElement('span')
      dataHolder.className = 'discussion-data-holder'
      dataHolder.dataset.from = discussion.range.from
      dataHolder.dataset.to = discussion.range.to
      dataHolder.dataset['decorationUuid'] = discussion.decorationUuid
      return dataHolder
    }

    const generateDecorations = (doc, offset = 0, offsetFrom= 0) => {

      let discussions = store.getters['discussions/allDiscussions']
      let decorations = []
      discussions?.forEach(discussion => {
        if (offsetFrom < discussion.range.from){
          discussion.range.from = discussion.range.from + offset
        }
        if (offsetFrom < discussion.range.to){
          discussion.range.to = discussion.range.to + offset
        }

        //create range decoration and data holder
        decorations.push( Decoration.inline(
            discussion.range.from,
            discussion.range.to,
            mergeAttributes(
              { class: `discussion-decoration id-${discussion.decorationUuid}` },
              { class: discussion.isActive ? 'is-active' : '' },
              { class: discussion.status === "Approved" && !discussion.isActive ? 'is-approve' : '' },
              { class: discussion.status === "Rejected" && !discussion.isActive ? 'is-reject' : '' },
            ),
          ),
          Decoration.widget(discussion.range.from, createDiscussionDataHolderWidget(discussion))
        )
      })

      store.commit('discussions/SET_STATE', {
        key: discussions,
        value: discussions,
      })

      return DecorationSet.create(doc, decorations.sort((a, b) => b.from - a.from))
    }
    return [
      new Plugin({
        state: {
          init(_, { doc }) { return generateDecorations(doc) },
          apply(tr, old) {
            let offset = 0
            let offsetFrom = 0
            if (tr.docChanged){
              let newContentSize = tr.steps[0].slice.content.size
              let { from, to } = tr.steps[0]
              let selectionSize =  from - to
              let docChangedFor = selectionSize + newContentSize
              offsetFrom = from
              offset = docChangedFor
            }

            return tr.docChanged ? generateDecorations(tr.doc, offset, offsetFrom) : old
          }
        },
        props: {
          decorations(state) {
            return generateDecorations(state.doc)
          },
          handleClick({dispatch, state}, _, event) {

            if (/discussion-decoration/.test(event.target.className)) {
              let decorationUuidsInPosition = event.target.classList.value
                .split(' ')
                .filter( className => className.startsWith('id-') )
                .map( classWithId => classWithId.replace('id-', '') )

              let discussionDataHolders = []

              //get all widgets with data
              decorationUuidsInPosition.forEach(decorationUuid => [
                discussionDataHolders.push( document.querySelector(`[data-decoration-uuid="${decorationUuid}"]`)?.dataset )
              ])

              if (discussionDataHolders.length){
                //sort them to set the shortest range ta first position
                discussionDataHolders = discussionDataHolders.sort((a, b) => {
                  return (a.to - a.from) - (b.to - b.from)
                })
                //get the first one
                const selectedDecorationUuId = discussionDataHolders[0].decorationUuid

                store.commit('discussions/SET_STATE', {
                  key: 'discussions',
                  value: store.getters['discussions/allDiscussions'].map(d => { d.isActive =  d.decorationUuid === selectedDecorationUuId; return d})
                })
              }

            } else {
              store.commit('discussions/SET_STATE', {
                key: 'discussions',
                value: store.getters['discussions/allDiscussions'].map(d => { d.isActive = false; return d})
              })
            }
            dispatch(state.tr.setSelection(TextSelection.create(state.doc, state.selection.from)))

            return true
          },

          // handleDoubleClick(view, _, event) {
          //   if (/lint-icon/.test(event.target.className)) {
          //     let prob = event.target.problem
          //     if (prob.fix) {
          //       prob.fix(view)
          //       view.focus()
          //       return true
          //     }
          //   }
          // }
        }
      })
    ]
  },

})
