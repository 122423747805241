import { Editor } from '@tiptap/vue-3'
import ApiService from '@/services/ApiService'
import store from '@/store'
import { isTextSelection, mergeAttributes, Node } from '@tiptap/core'
import {Fragment} from "vue";
/* official extensions */
import Text from '@tiptap/extension-text'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
// import TextAlign from '@tiptap/extension-text-align'
import Heading from '@tiptap/extension-heading'
import BulletList from '@tiptap/extension-bullet-list'
import HardBreak from '@tiptap/extension-hard-break'
import History from '@tiptap/extension-history'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import { Paragraph } from '@tiptap/extension-paragraph'
// import Document from '@tiptap/extension-document'
import Link from '@tiptap/extension-link'
import TextStyle from '@tiptap/extension-text-style'
import Highlight from '@tiptap/extension-highlight'
import BubbleMenu from '@tiptap/extension-bubble-menu'
import Table from '@tiptap/extension-table'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TableCell from '@tiptap/extension-table-cell'
import Gapcursor from '@tiptap/extension-gapcursor'

/* custom extensions */
import CCUtils, { editorModes } from '@/utils/editor/extensions/utils'
import ShortcodesConverter from '@/utils/editor/extensions/shortcodesConverter'
import SignaturesConverter from '@/utils/editor/extensions/signaturesConverter'
import ShortcodesValidator from '@/utils/editor/extensions/shortcodesValidator'
import { Shortcode } from '@/utils/editor/extensions/shortcodes/shortcode'
import NodeInserter from '@/utils/editor/extensions/nodeInserter'
import ContentSaver from '@/utils/editor/extensions/contentSaver'
import Signature from '@/utils/editor/extensions/signature/index'
import DiscussionDecorations from '@/utils/editor/extensions/discussionDecorations/discussionDecorations'
import { Document } from '@/utils/editor/extensions/document/document'
import { Page } from '@/utils/editor/extensions/page/page'
import { ContentWrapper } from '@/utils/editor/extensions/contentWrapper/contentWrapper'
import TextAlign from '@/utils/editor/extensions/TextAlign'
import DocumentFontFamily from '@/utils/editor/extensions/documentFontFamily'

export const initTemplateEditorWithContent = ({
  html,
  template,
  editable = true,
  mode = 'edit',
}) => {
  CCUtils.storage.mode = mode

  return new Editor({
    editable,
    injectCSS: true,
    content: html,
    extensions: [
      CCUtils,
      Bold,
      Text,
      Italic,
      Underline,
      TextAlign,
      // TextAlign.configure({
      //   types: ['heading', 'paragraph'],
      // }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      BulletList,
      HardBreak,
      History,
      ListItem,
      OrderedList,
      Paragraph,
      Document,
      // Page,
      ContentWrapper,
      DocumentFontFamily,
      Link,
      TextStyle,
      Highlight,
      ShortcodesConverter,
      SignaturesConverter,
      Shortcode,
      NodeInserter,
      Signature.configure({
        getSignatureUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.signature
          } else {
            return false
          }
        },
        getInitialsUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.initials_sign
          } else {
            return false
          }
        },
      }),
      ShortcodesValidator,
      ContentSaver.configure({
        //please, provide here promise as returned value
        callback: (html) => {
          let formData = {
            content: html,
          }
          return ApiService.api.template.updateTemplate({
            template_id: template.template_id,
            formData,
          })
        },
      }),
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableHeader,
      TableCell,
      Gapcursor,
    ],
  })
}

export const initContractEditorWithContent = ({
  html,
  contract,
  editable = true,
  mode = 'edit',
}) => {
  CCUtils.storage.mode = mode
  CCUtils.storage.contract = contract

  return new Editor({
    editable,
    injectCSS: true,
    content: html,
    extensions: [
      CCUtils,
      Bold,
      Text,
      Italic,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      BulletList,
      HardBreak,
      History,
      ListItem,
      OrderedList,
      Paragraph,
      Document,
      // Page,
      ContentWrapper,
      DocumentFontFamily,
      Link,
      TextStyle,
      Highlight,
      ShortcodesConverter,
      SignaturesConverter,
      // Shortcode,
      NodeInserter,
      Signature.configure({
        getSignatureUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.signature
          } else {
            return false
          }
        },
        getInitialsUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.initials_sign
          } else {
            return false
          }
        },
        setSignatureCallback: async ({
          signature_id,
          signature_type,
          contract_id,
        }) => {
          return ApiService.api.contracts.setSignature({
            signature_id,
            signature_type,
            contract_id,
          })
        },
      }),
      ShortcodesValidator,
      ContentSaver.configure({
        //please, provide here promise as returned value
        callback: (html) => {
          return Promise.resolve
          // let formData = {
          //   content: html,
          //   name: template.template_name,
          //   is_published: template.is_draft ? 0 : 1,
          //   description: template.template_description,
          // }
          // return ApiService.api.template.updateTemplate({ template_id: template.template_id, formData })
        },
      }),
      Table,
      TableRow,
      TableHeader,
      TableCell,
    ],
  })
}

export const initTaskReviewAndApproveEditorWithContent = ({
  html,
  task = null,
}) => {
  CCUtils.storage.mode = editorModes.MODE_EDIT

  return new Editor({
    editable: false,
    injectCSS: true,
    content: html,
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
    extensions: [
      CCUtils,
      Bold,
      Text,
      Italic,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      BulletList,
      HardBreak,
      History,
      ListItem,
      OrderedList,
      Paragraph,
      Document,
      // Page,
      ContentWrapper,
      DocumentFontFamily,
      Link,
      TextStyle,
      Highlight,
      ShortcodesConverter,
      SignaturesConverter,
      // Shortcode,
      // NodeInserter,
      Signature.configure({
        getSignatureUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.signature
          } else {
            return false
          }
        },
        getInitialsUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({
            user_id: store.state.app?.user?.id,
          })
          if (res.status === 200) {
            return res.data?.data?.initials_sign
          } else {
            return false
          }
        },
      }),
      ShortcodesValidator,
      // Proposition,
      // DiscussionTarget,
      DiscussionDecorations,
      BubbleMenu.configure({
        element: document.querySelector('.bubble-menu'),
        tippyOptions: { placement: 'right', duration: 500, offset: [0, 40] },
        shouldShow: ({ view, state, from, to }) => {
          const { doc, selection } = state
          const { empty } = selection
          /* Check if button already visible or discussionModal oppened */
          const button = document.querySelector('.bubble-menu')
          if (
            (button && button?.style?.visible === 'visible') ||
            document.querySelector('.discussion-create-modal')
          )
            return false

          // Sometime check for `empty` is not enough.
          // Doubleclick an empty paragraph returns a node size of 2.
          // So we check also for an empty text size.
          const isEmptyTextBlock =
            !doc.textBetween(from, to).length &&
            isTextSelection(state.selection)

          if (empty || isEmptyTextBlock) {
            return false
          }

          return true
        },
      }),
      ContentSaver.configure(/*{
        //please, provide here promise as returned value
        callback: (html) => {
          let formData = {
            content: html,
            name: template.template_name,
            is_published: template.is_draft ? 0 : 1,
            description: template.template_description,
          }
          return ApiService.api.template.updateTemplate({ template_id: template.template_id, formData })
        }
      }*/),
      Table,
      TableRow,
      TableHeader,
      TableCell,
    ],
  })
}
export const initTaskReviewAndApproveParser = () => {
  return new Editor({
    editable: false,
    injectCSS: true,
    content: '',
    editorProps: {
      attributes: {
        spellcheck: 'false',
      },
    },
    extensions: [
      Bold,
      // Image,
      Text,
      Italic,
      Underline,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Heading.configure({
        levels: [1, 2, 3],
      }),
      BulletList,
      HardBreak,
      History,
      ListItem,
      OrderedList,
      Paragraph,
      Document,
      // Page,
      ContentWrapper,
      DocumentFontFamily,
      Link,
      TextStyle,
      Highlight,
      Table,
      TableRow,
      TableHeader,
      TableCell,
      // ShortcodesConverter,
      // SignaturesConverter,
      // Shortcode,
      // NodeInserter,
      /*Signature.configure({
        getSignatureUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({ user_id:  store.state.app?.user?.id})
          if (res.status === 200){
            return res.data?.data?.signature
          } else {
            return false
          }
        },
        getInitialsUrl: async () => {
          let res = await ApiService.api.userProfiles.showProfile({ user_id:  store.state.app?.user?.id})
          if (res.status === 200){
            return res.data?.data?.initials_sign
          } else {
            return false
          }
        },

      }),*/
      // ShortcodesValidator,
      // Proposition,
      // DiscussionTarget,
      // DiscussionDecorations,
    ],
  })
}
